<template>
    <div v-if="blok">
        <nuxt-link :id="blok.uuid"
            :to="'/' + blok.full_slug"
            :aria-label="blok.content?.title"
            class="flex flex-col space-y-md">
            <nuxt-picture v-if="blok.content?.image?.filename"
                :src="blok.content.image.filename"
                :alt="blok.content.title"
                class="aspect-video shrink-0 md:aspect-square"
                :preload="{ fetchPriority: 'low' }"
                provider="storyblok"
                sizes="xs:100vw sm:640px md:768px lg:1024px xl:1280px 2xl:1536px"
                :img-attrs="{
                    class: 'rounded-2xs object-cover w-full h-full'
                }" />

            <div class="space-y-xs">
                <p class="text-xs tracking-3xl text-gray-500">
                    {{ localDate(blok.created_at) }}
                </p>

                <span class="font-prenton-cond text-2xl tracking-2xl xl:text-3xl">
                    {{ blok.content?.title }}
                </span>

                <!-- <div>
                    <p class="text-xs tracking-3xl text-gray-500">
                        {{ blok.content?.tags }}
                    </p>
                </div> -->
            </div>
        </nuxt-link>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/naming-convention */
import type { ISbStoryData } from 'storyblok-js-client';

defineProps<{
    article?: string
    blok: ISbStoryData
}>();

function localDate(date: string) {
    return new Date(date).toLocaleDateString('fi-FI');
}
</script>
